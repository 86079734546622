import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

//pages 
import DefaultPage from '../pages/DefaultPage';
import PtpPaidPage from '../pages/PtpPaidPage';

function Main() {


    return (
      <>
        <Routes>
            <Route path="/" element={<DefaultPage />}  key={1} />
            <Route path="*" element={<Navigate to="/" />}  key={2}/>
            <Route path="/ptp/:ptpId/groups/:groupId" element={<PtpPaidPage />}  key={3} />
        </Routes>
      </>
    );
}

export default Main;