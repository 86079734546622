import '../../App.css';
import logo from '../../logo.svg';

function DefaultPage(props) {

    return (
        <div>
            <header className="App-header">
                <img src={logo} className="App-logo" alt="Rotas Square" />
                <span>&nbsp;<br/></span>
                <a
                className="App-link"
                href="mailto:info@rotas-square.co.za"
                target="_blank"
                rel="noopener noreferrer"
                >
                info@rotas-square.co.za
                </a>
            </header>
        </div>
    );
}

export default DefaultPage;