const apiKey = process.env.REACT_APP_PTP_Api_Key;

export const markPtpPaid = async (postData) =>
    await new Promise((resolve, reject) => {
        fetch(`https://spoonptp.azurewebsites.net/api/ptp/paid`, {
            method: "post",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            },
            body: JSON.stringify(postData),
        }).then((response) => {
                resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });


export const getPtpProgress = async (ptpId) =>
    await new Promise((resolve, reject) => {
        fetch(`https://spoonptp.azurewebsites.net/api/ptp/${ptpId}`, {
            method: "get",
            headers: {
            "Content-Type": "application/json",
            "x-functions-key": apiKey,
            }
        }).then((response) => {
                resolve(response.json());
        }).catch((reqErr) => {
            reject(reqErr);
        });
    });