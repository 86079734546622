import { useState, useEffect } from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// @mui icons
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from '@mui/icons-material/Error';


function FailModal(props) {
    
    const toggleModal = () => setShow(!show);
    const [show, setShow] = useState(props.show);
    const [uri, setUri] = useState(props.uri);

    useEffect(() => {
        let cancel = false;
          if(cancel)
            return;
       
            setUri(props.uri);
    
        return () => { 
          cancel = true;
        }
    
      }, [props.uri]);

    useEffect(() => {
        let cancel = false;
          if(cancel)
            return;
       
            setShow(props.show);
    
        return () => { 
          cancel = true;
        }
    
      }, [props.show]);
      
    return (
        <Modal 
            disableAutoFocus={true}
            open={show} onClose={toggleModal} 
            sx={{ border: "none", display: "grid", placeItems: "center" }}
        >
            <Slide direction="down" in={show} timeout={500}>
            <Box
                position="relative"
                width="500px"
                flexDirection="column"
                sx={{backgroundColor: "white", borderRadius: 10, display: "flex"}}
            >
                <Grid container sx={{ textAlign: "center" }}>
                    <Grid item xs={12} sx={{ mt: -3 }}>
                        <Typography sx={{fontSize: "50px"}}>
                            <ErrorIcon style={{"color": "#f44336", "height": "100px", "width": "100px"}}  />
                        </Typography>
                        <Typography variant="h2" color="dark">Oh no!</Typography>
                    </Grid>
                </Grid>
                <Divider sx={{ my: 0 }} />
                <Box borderRadius="xl" bgColor="white" display="flex" alginItems="center" justifyContent="space-between" p={2}>
                
                <Typography variant="h5" color="dark">{props.header}</Typography>
                <CloseIcon color="dark" fontSize="medium" sx={{ cursor: "pointer" }} onClick={toggleModal} />
                </Box>
                <Divider sx={{ my: 0 }} />
                <Box p={2}>
                <Typography variant="body2" color="secondary" fontWeight="regular">
                    {props.text}
                </Typography>
                </Box>
                <Divider sx={{ my: 0 }} />
                <Box display="flex" justifyContent="space-between" p={1.5}>
                <Button variant="gradient" color="dark" href={uri}>
                    Go Back
                </Button>
                </Box>
            </Box>
            </Slide>
        </Modal>
    );
}

export default FailModal;