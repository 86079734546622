import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { Formik, Form } from 'formik';
import * as yup from 'yup';

import FailModal from "../../components/modals/FailModal";

import { getPtpProgress, markPtpPaid } from '../../api/ptp';

const validationSchema = yup.object({
  paidAmount: yup.number().typeError('A valid amount is required').min(1, "An amount larger than R1 is required").required("Amount paid is required")
});


function PtpPaidPage(props) {
    let { ptpId, groupId } = useParams();
    const [showError, setShowError] = useState(false);
    const [showErrorHeading, setShowErrorHeading] = useState("PTP Download Error");
    const [showErrorMsg, setShowErrorMsg] = useState("The PTP could not be downloaded, go back and check if status is in `Progress'");
    const [showSuccess, setShowSuccess] = useState(false);
    const [backdropClose, setBackdropClose] = useState(true);
    const [ptpData, setPtpData] = useState(null);
    const [formValues, setFormValues] = useState({
      id: "",
      paidAmount: ""
    });

    useEffect(() => {
        let cancel = false;
        if(cancel)
          return;
        
        setFormValues({
          id: ptpId,
          paidAmount: ""
        });

        getPtpProgress(ptpId)
            .then((data) => {
              setBackdropClose(false);
              setPtpData(data);
            })
            .catch((err) => {
              setShowError(true);
              setBackdropClose(false);
            });
    
      return () => { 
              cancel = true;
            }
      }, [ptpId]);

    
      useEffect(() => {
        let cancel = false;
        if(cancel)
          return;

    if(showSuccess) {
        window.location.assign(`https://www.appsheet.com/start/d9899b34-59c5-4f53-bcb1-bc37d68de0a5#appName=CustomerService-1001740-20-04-03&group=%5B%5D&row=${groupId}&sort=%5B%7B%22Column%22%3A%22Group+Name%22%2C%22Order%22%3A%22Ascending%22%7D%5D&table=Active+Groups&view=Active+Groups_Detail`);
    }

        
    
      return () => { 
              cancel = true;
            }
      }, [showSuccess, groupId]);

    return (
        <>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropClose}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Box component="section" p={2} method="post">
              <Box px={3} py={{ xs: 2, sm: 6 }}>
                <Typography variant="h2" mb={1}>
                  PTP Details
                </Typography>
                <Typography variant="body1" color="text" mb={2}>
                  Enter amount paid for the PTP.
                </Typography>
              </Box>
              <Box pt={0.5} pb={3} px={3}>
                <Formik
                  enableReinitialize={true}
                  initialValues={formValues}
                  validationSchema={validationSchema}
                  onSubmit={values => {
                    setBackdropClose(true);
                    markPtpPaid(values)
                      .then((data) => {
                        setBackdropClose(false);
                        setShowSuccess(true);
                      })
                      .catch((err) => {
                        setShowErrorHeading("PTP Paid Error");
                        setShowErrorMsg("The PTP could not be updated, go back and check if status is in `Progress'");
                        setShowError(true);
                        setBackdropClose(false);
                      });
                    /*const productInfo = {
                      coverId: coverId
                    };
                    props.setStep1Values({...values, ...productInfo});
                    props.setCurrentStep(2);*/
                  }}
              >
              {(props) => (
                <Form>
                  <Grid container spacing={1} alignItems="center" sx={{ textAlign: "center" }}>
                  {ptpData && <Grid item xs={12}>
                        <TextField 
                          type="text" 
                          label="Status"
                          id="status" 
                          value={ptpData.status}
                          fullWidth 
                          disabled={true}
                        />
                      </Grid>}
                      {ptpData && <Grid item xs={12}>
                        <TextField 
                          type="text" 
                          label="Date"
                          id="date" 
                          value={ptpData.date}
                          fullWidth 
                          disabled={true}
                        />
                      </Grid>}
                      {ptpData && <Grid item xs={12}>
                        <TextField 
                          type="text" 
                          label="Comments"
                          id="comments" 
                          value={ptpData.comments}
                          fullWidth 
                          disabled={true}
                        />
                      </Grid>}
                      {ptpData && <Grid item xs={12}>
                        <TextField 
                          type="text" 
                          label="Amount"
                          id="amount" 
                          value={ptpData.amount}
                          fullWidth 
                          disabled={true}
                        />
                      </Grid>}
                      <Grid item xs={12}>
                        <TextField 
                            label="PTP Amount Paid" 
                            id="paidAmount" 
                            value={props.values.paidAmount}
                            onChange={props.handleChange('paidAmount')}
                            error={props.errors.paidAmount && Boolean(props.errors.paidAmount)}
                            fullWidth
                          >
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="caption" color="error" align="center">
                          {props.errors.paidAmount && props.errors.paidAmount}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}  sx={{ textAlign: "center" }}>
                        <Button type="submit" color="info" sx={{ height: "100%" }}>
                            Mark Paid
                        </Button>
                      </Grid>
                    </Grid>
                  </Form>
                  )}
              </Formik>
              </Box>
            </Box>
            <FailModal show={showError} header={showErrorHeading} text={showErrorMsg} uri={`https://www.appsheet.com/start/d9899b34-59c5-4f53-bcb1-bc37d68de0a5#appName=CustomerService-1001740-20-04-03&group=%5B%5D&row=${groupId}&sort=%5B%7B%22Column%22%3A%22Group+Name%22%2C%22Order%22%3A%22Ascending%22%7D%5D&table=Active+Groups&view=Active+Groups_Detail`} />
        </>
      );
}

export default PtpPaidPage;